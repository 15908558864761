
import { defineComponent, ref, onMounted, computed } from "vue"
import ApiService from "@/core/services/ApiService"
import { ElForm } from "element-plus"
import { getRule, RuleTypes } from "@/core/helpers/rules"
import { MenuAction, RoleUser } from "@/core/models/User"

export default defineComponent({
  name: "menu-action",
  components: {},
  setup() {
    const selectedUserId = ref(null)
    const roleUserList = ref<RoleUser[]>([])

    type FormInstance = InstanceType<typeof ElForm>

    const ruleFormRef = ref<FormInstance>()

    const computedMenuList = computed(() => {
      if (selectedMenuList.value) {
        if (!showAllMenus.value)
          return selectedMenuList.value.filter(x => x.userBranchActions.length > 0)
        else return selectedMenuList.value
      } else return []
    })

    const selectedMenuList = ref<MenuAction[]>([] as MenuAction[])
    const selectedActions = ref([])
    const isLoading = ref(false)
    const showAllMenus = ref(false)
    const businessDetail = ref([])
    const selectedBranch = ref([])

    const getBusinessDetail = async () => {
      const { data } = await ApiService.get("business/detail")
      businessDetail.value = data.filter(x => x.branches.length > 0)
    }

    async function getMenuUser() {
      isLoading.value = true
      const { data } = await ApiService.get(
        "action/get-by-branch-user/" + selectedUserId.value + "/" + selectedBranch.value?.[1][1]
      )
      selectedMenuList.value = data
      isLoading.value = false
    }

    async function getUsers() {
      const { data } = await ApiService.get("user")
      roleUserList.value = data
    }

    async function addUserMenu(menuId) {
      await ApiService.post("menu/add-user-menu", {
        menuId: menuId,
        userId: selectedUserId.value,
      })
      await getMenuUser()
    }

    function userMenuChange(data) {
      toggleUserMenu(data.id)
    }

    async function toggleUserMenu(id) {
      isLoading.value = true
      await ApiService.post("action/toggle-user-action", {
        accountUserId: selectedUserId.value,
        branchId: selectedBranch.value?.[1][1],
        action: id,
      })
      isLoading.value = false
      await getMenuUser()
    }

    const formdata = computed(() => ({
      selectedUserId: selectedUserId.value,
      selectedBranch: selectedBranch.value,
    }))

    const rules = ref({
      selectedUserId: getRule(RuleTypes.SELECT, "Kullanıcı"),
      selectedBranch: getRule(RuleTypes.SELECT, "Şube"),
    })

    const handleList = formEl => {
      if (!formEl) return
      formEl.validate(async valid => {
        if (valid) {
          await getMenuUser()
        }
      })
    }

    onMounted(async () => {
      await getUsers()
      await getBusinessDetail()
    })

    const defaultProps = {
      children: "childMenuList",
      label: "name",
      disabled: "hasChild",
    }

    const casProps = {
      children: "branches",
      label: "title",
      value: "id",
    }

    return {
      selectedUserId,
      defaultProps,
      userMenuChange,
      addUserMenu,
      getMenuUser,
      handleList,
      selectedMenuList,
      roleUserList,
      businessDetail,
      casProps,
      selectedBranch,
      selectedActions,
      isLoading,
      showAllMenus,
      computedMenuList,
      formdata,
      rules,
      ruleFormRef,
    }
  },
})
